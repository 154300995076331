import openGalleryFromElementData from './open-gallery-from-element-data';

export default function urlOpenMatcher() {
  const galleryHashRegex = /\#\&gid\=([a-z0-9A-z-]*)\&pid\=([0-9]*)/gm;
  const hash = window.location.hash;

  const array = [...hash.matchAll(galleryHashRegex)];
  if (array.length) {
    const [all, name, indexString] = array[0];
    const selector = `#${name}`;
    const galleryIndex = Number(indexString);
    const arrayIndex = galleryIndex - 1;
    let thumbElements = document.querySelectorAll(
      `${selector} img.thumb-image`,
    );
    if (thumbElements.length === 0) {
      thumbElements = [document.querySelector(selector)];
    }
    openGalleryFromElementData(
      selector,
      'galleryItems',
      arrayIndex,
      galleryIndex,
      thumbElements[arrayIndex],
    );
  }
}
