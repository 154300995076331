export default class BodyController {
  promotionHash = 'promocje';
  promotionOffset = 64;

  pagesRegex = new RegExp(/^\?page\=([0-9])+$/, 'g');

  constructor($scope, $document) {
    this.scope = $scope;
    this.document = $document;
  }

  $postLink() {
    this.onPaginationInitChange();
  }

  onPaginationInitChange() {
    if (location.hash) {
      try {
        if (
          location.hash === `#${this.promotionHash}` &&
          this.pagesRegex.test(location?.search)
        ) {
          const sectionElement = angular.element(
            document.getElementById(`${this.promotionHash}`),
          );
          if (sectionElement) {
            this.document.scrollToElement(
              sectionElement,
              this.promotionOffset,
              1,
            );
          }
        } else {
          const sectionElement = angular.element(
            document.querySelector(location.hash),
          );
          if (sectionElement) {
            this.document.scrollToElement(
              sectionElement,
              this.promotionOffset,
              1,
            );
          }
        }
      } catch {}
    }
  }
}
