import BodyController from './modules/body.controller';
// import PageRotorController from './modules/page-rotor.controller';
// import GalleryRotorController from './modules/gallery-rotor.controller';
// import ContactFormController from './modules/contact-form.controller';
// import PopupController from './modules/popup.controller';
// import CookieController from './modules/cookie.controller';
import clickOpen from './modules/gallery/click-open';
import urlOpenMatcher from './modules/gallery/url-open-matcher';
// import PricesController from './modules/prices.controller';
import './helpers/lazy-images';
// const angular = window.angular;

angular
  .module(
    'app',
    [
      'hmTouchEvents',
      'duScroll',
      /*'ngSanitize', 'ngCookies'*/
    ],
    ($interpolateProvider) => {
      $interpolateProvider.startSymbol('[[');
      $interpolateProvider.endSymbol(']]');
    },
  )
  .config(function ($sceDelegateProvider) {
    $sceDelegateProvider.resourceUrlWhitelist(['**']);
    $sceDelegateProvider.trustedResourceUrlList(['self', '**']);
    $sceDelegateProvider.bannedResourceUrlList(['**']);
  })
  .config(($locationProvider) => {
    $locationProvider.html5Mode(false);
    $locationProvider.hashPrefix('');
  })
  .run(() => {
    const navClassChange = () => {
      const nav = document.getElementById('navId');
      const header = document.getElementById('strona-glowna');
      if (nav && header) {
        if (window.scrollY > header.offsetHeight - 128) {
          nav.classList.add('out-of-header');
        } else {
          nav.classList.remove('out-of-header');
        }
      }
    };

    document.addEventListener('scroll', () => {
      navClassChange();
    });

    navClassChange();
  })
  // .factory('isProblemResolution', ($window) => {
  //   return () => {
  //     let w = $window.innerWidth;
  //     let h = $window.innerHeight;
  //     return w > h && w > 1200 && w < 1400 && h <= 800;
  //   };
  // })
  .factory('AppService', ($location) => {
    return {
      url: $location.protocol() + '://' + $location.host(), //+ ':3000',
    };
  })
  .filter('isValid', function () {
    return function (obj) {
      var bool = true;

      Object.keys(obj).forEach(function (key) {
        if (!obj[key].isValid) {
          bool = false;
        }
      });

      return bool;
    };
  })
  // .directive('a', function () {
  //   return {
  //     restrict: 'E',
  //     link: function (scope, elem, attrs) {
  //       elem.on('click', function (e) {
  //         // console.log(e.target);
  //         if (e.target.href) {
  //           if (e.target.href.indexOf('#') !== -1 && e.target.href != '#') {
  //             const split = e.target.href.split('#');
  //             if (split[split.length - 1] !== '') {
  //               const element = document.querySelector(
  //                 '#' + split[split.length - 1],
  //               );
  //               const y =
  //                 element.getBoundingClientRect().top +
  //                 window.pageYOffset -
  //                 180;
  //               window.scrollTo({ top: y, behavior: 'smooth' });
  //             }
  //           }
  //         }
  //       });
  //     },
  //   };
  // })
  .controller('BodyController', ['$scope', '$document', BodyController]);

// .controller('PricesController', ['$element', PricesController]);
// .controller('ContactFormController', [
//   '$http',
//   '$scope',
//   '$document',
//   '$filter',
//   'AppService',
//   ContactFormController,
// ]);
// .controller('CookieController', ['$scope', '$cookies', CookieController]);
document.addEventListener('DOMContentLoaded', () => {
  urlOpenMatcher();
});

[...document.querySelectorAll('.click-open')].forEach((el) => {
  el.addEventListener('click', (ev) => {
    clickOpen(ev.target);
  });
});
//
// [...document.querySelectorAll('.gallery-r-element')].forEach((el) => {
//   el.addEventListener('click', (ev) => {
//     openFromThumbGallery(ev.target);
//   });
// });
//
// [...document.querySelectorAll('.gallery-collection')].forEach((el) => {
//   el.addEventListener('click', (ev) => {
//     openFromCollectionProgress(
//       ev.target.dataset.collection,
//       0,
//       'progress',
//       ev.target,
//     );
//   });
// });
